/*You can add global styles to this file, and also import other style files */
@import "../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";  

@import "../node_modules/@uitk/icons/webfonts/stylesheets/micro-interaction-icons.css";
@import "../node_modules/@uitk/angular/solas.css";

@import "~ng-pick-datetime/assets/style/picker.min.css";

.wrapper {
  display: flex;
  padding: 0px;
  flex-direction: column;
}

.uitk-c-footer {
  padding: 0 0 0 0 !important;
}

.uitk-c-footer__copy {
  margin-right: 2px !important;
  margin-bottom: 0px !important;
  margin-left: 15px;
  position: absolute !important;
  bottom: 0;
}

.uitk-l-grid {
  flex: 1;
}

.uitk-c-header {
  padding: 0 0 0 2rem !important;
}

.uitk-l-grid {
  padding: 0 0 0 0 !important;
}

.uitk-l-grid__col--4 {
  padding: 1px !important;
}

.uitk-c-form-field{
    flex-direction: row !important;
}
.uitk-c-form__control{
    padding-top: 4px !important;
    padding-bottom: 0px !important;
    height:22px;
    font-size: small !important;
}

hr.line1 {
  border: 2px solid black;
  width: 100%;
  padding: 0px;
  margin: 0px;
  background-color: black;
}
/*AG-Grid styles*/
.ag-row-hover {
  /* putting in !important so it overrides the theme's styling as it hovers the row also */
  background-color:#fae881 !important;
}
 .ag-row.row-hightligt {
  background-color: #ffc107 !important;
  border: 1px solid black !important;
}
.ag-row-selected {
  background-color: #ffc107 !important;
  border: 1px solid black !important;
}
.ag-header {
    height: 30px !important;
    /* height: 30px !important; */
    min-height: 28px !important;
}
.ag-header-row {
  height: 26px !important;
  background-color: rgb(131, 120, 120) !important;
  color: rgb(255, 255, 255) !important;
}
.ag-row{
  font-size: 12px !important;
  height: 26px !important;
  padding-top: 1px;
}
/*::ng-deep .ag-theme-alpine .ag-cell {
  line-height: 20px !important;
}
 /* .ag-cell-focus {
  border: 1px solid red !important;
} */
.uitk-c-fieldset__grouping{
  border: none !important;
  padding: 0px !important;
}
 .ag-cell{
  height:22px;
}

.uitk-c-dropdown--expanded {
  overflow: hidden !important;
}
